import {constructor} from '../../../../mixins/constructor';

export default {
    name: "civil",
    mixins: [constructor],
    data() {
        return {
            complexId: '',
            hoverdBuilding: '',
            selectedBuilding: '',
            hoveredBuildingElement: '',
            selectedBuildingElement: '',
            categoryList: '',
            coords: [
                '52,121,98,150,82,164,98,174,89,182,93,189,88,191,106,202,92,210,100,217,96,219,200,277,209,273,229,279,381,190,389,197,408,186,471,148,467,141,506,117,487,106,499,97,489,88,458,104,431,89,457,76,444,66,433,71,415,67,395,78,355,50,369,41,407,22,376,2,356,9,344,0,227,68,216,64,177,86,151,73,151,62,120,48,70,80,59,96,64,103,60,113',
                '500,175,779,11,785,16,795,5,874,50,873,63,931,95,948,92,962,95,983,109,996,122,1008,140,1007,158,1039,177,873,276,753,209,654,263',
                '264,312,317,283,319,267,421,207,490,246,489,254,563,306,479,356,465,349,395,386',
                '34,545,119,597,130,590,143,600,270,529,293,541,369,497,343,477,406,437,348,402,313,423,289,404,268,413,258,410,100,504,77,505',
                '611,357,723,422,784,386,797,393,906,329,839,289,834,263,789,240,782,230,735,257,731,262,715,251,665,279,661,327',
                '604,521,689,571,701,563,780,606,788,601,795,609,859,570,875,581,884,576,896,584,901,579,913,584,918,579,927,584,932,580,943,578,952,584,996,559,990,552,994,550,991,547,995,544,989,538,994,535,984,526,988,523,969,509,975,506,967,502,974,500,960,484,958,454,947,452,937,435,929,426,913,415,891,404,844,402,840,395,798,397,795,408,779,420,739,424,698,446,669,452,649,462,656,468,654,475,652,492',
                '612,222,612,235,653,257,661,245,690,226,700,226,725,211,728,198,743,194,830,243,871,297,851,334,829,346,829,360,871,381,877,368,890,361,904,353,914,346,919,338,919,285,912,269,854,198,765,147,722,156,710,150,686,148,673,152,631,209',
                '370,404,559,295,560,247,372,353,344,345,337,352,319,364,330,372,335,365,339,358,365,366',
                '122,199,108,206,169,244,178,238',
                '276,187,329,219,318,227,307,237,259,209,259,199,265,190',
                '200,203,233,172,272,155,292,149,312,164,256,190,222,216',
                '279,342,328,314,331,332,281,359',
                '543,189,593,162,596,180,544,209',
                '514,131,530,126,530,136,519,142',
                '216,306,264,279,269,297,214,325',
                '348,308,365,323,367,342,568,223,565,207,543,194',
                '244,274,246,251,264,260,462,147,445,138,249,249',
                '289,405,305,408,317,418,332,411,363,380,356,367,344,364,339,365,311,392',
                '561,266,588,264,605,256,597,242,585,239,577,238,562,248',
                '271,265,277,259,286,255,300,260,314,269,328,287,331,301',
                '309,259,517,142,530,157,542,177,541,186,340,299',
                '286,254,298,255,500,137,488,132',
                '439,525,944,230,957,250,444,550,444,538',
                '323,484,317,472,317,459,830,165,826,197',
                '497,287,541,314,463,357,422,335,425,325,460,343,539,301,500,277',
                '378,302,414,317,410,332,378,313',
                '573,245,594,259,613,250,615,264,595,275,573,264',
                '598,279,598,316,689,262,687,170,646,191,647,252',
                '520,331,536,322,536,353,521,362',
                '378,316,420,339,421,380,378,359',
                '440,350,455,359,477,355,481,386,457,401,439,394',
                '596,158,638,133,687,163,646,185',
                '545,171,568,157,588,172,567,185',
                '580,192,601,180,622,191,601,204',
                '687,329,706,318,726,323,738,335,749,352,737,361,724,341,704,331',
                '100,422,156,370,210,359,258,391,205,403,156,453',
                '161,460,177,438,205,416,234,407,259,406,355,462,330,463,305,472,278,495,260,518',
                '216,362,258,345,305,374,262,392',
                '748,188,747,169,759,143,771,133,786,131,864,178,867,208,855,214,855,186,841,194,842,220,822,231',
                '379,262,456,219,495,241,422,287',
                '497,286,538,310,594,279,556,257',
                '324,286,350,277,383,268,418,267,450,270,490,276,491,318,451,310,419,307,384,308,352,316,325,328',
                '147,304,226,345,319,292,323,330,311,335,306,338,305,372,229,412,147,370',
                '583,129,610,131,643,133,672,137,702,144,732,155,761,173,785,199,795,230,739,229,734,204,719,185,701,172,670,165,647,157,588,152',
                '511,152,528,185,556,211,598,236,636,248,683,254,675,237,666,218,652,201,639,190,619,175,599,165,561,153',
                '519,173,541,197,573,225,619,244,655,253,652,268,618,260,581,247,551,225,531,205,516,189',
                '335,205,362,252,364,233,337,189',
                '366,234,399,229,431,228,464,234,499,238,498,256,465,253,430,249,402,250,371,253,366,253',
                '505,244,540,269,587,290,651,307,725,315,832,313,833,361,810,361,806,379,808,422,761,423,698,420,655,413,611,406,585,396,558,383,534,366,517,348,508,332,502,318',
                '135,263,168,249,196,242,214,239,237,232,327,283,306,293,278,295,254,300,231,312,222,317',
                '335,421,353,396,381,374,526,454,496,475,480,501',
                '503,325,512,347,554,384,618,407,694,421,724,424,668,441,619,442,576,437,545,424,517,407,499,385,494,360,496,341',
                '587,305,810,176,846,170,876,178,908,199,933,227,952,259,730,387,714,357,688,329,659,309,627,299',
                '586,309,726,386,711,359,686,332,658,311,625,299,601,301',
                '483,219,706,93,709,164,489,290',
                '345,141,483,219,483,248,344,172',
                '424,294,458,308,593,234,594,253,459,326,421,313',
                '423,336,457,350,594,274,594,296,456,373,421,357',
                '421,379,458,398,592,319,595,341,460,416,421,402',
                '422,428,457,441,592,366,596,384,459,460,421,445',
                '544,99,559,91,561,100,573,93,578,108,561,119,561,134,545,141,544,129,531,135,530,120,544,115',
                '300,205,317,215,341,215,342,350,315,340,301,324',
                '610,207,636,220,667,204,668,341,638,354,610,342',
                '612,187,724,121,754,138,640,204',
                '358,216,359,255,423,223,471,241,499,226,422,181',
                '390,277,490,217,527,233,416,295,397,291',
                '295,230,491,346,750,197,755,216,693,254,693,331,655,356,654,313,643,311,635,323,633,367,611,379,615,339,600,335,595,347,594,389,573,403,572,362,563,359,554,369,554,413,494,445,293,335,293,298,316,314,345,334,376,356,417,382,492,407,493,352,294,241',
                '294,238,493,353,494,406,448,395,400,372,355,344,295,296',
                '322,158,375,188,375,231,322,201',
                '448,230,503,258,502,302,450,273',
                '562,295,617,328,617,371,562,343',
                '690,373,743,400,744,444,691,415'

            ],

            radioArr: [],
            coordsArr: []
        }
    },
    watch: {},
    created() {
    },
    mounted() {

    },
    computed: {},
    methods: {}
}



